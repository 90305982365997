import angular from 'angular';

import { r2a } from '@/react-tools/react2angular';
import { withControlledInput } from '@/react-tools/withControlledInput';
import { Note } from '@/react/kubernetes/applications/components/Note';
import { withUIRouter } from '@/react-tools/withUIRouter';
import { withCurrentUser } from '@/react-tools/withCurrentUser';
import { ApplicationsDatatable } from '@/react/kubernetes/applications/ListView/ApplicationsDatatable/ApplicationsDatatable';

export const applicationsModule = angular
  .module('portainer.kubernetes.react.components.applications', [])
  .component(
    'note',
    r2a(withControlledInput(Note), [
      'defaultIsOpen',
      'value',
      'onChange',
      'labelClass',
      'inputClass',
      'isRequired',
      'minLength',
      'isExpandable',
    ])
  )
  .component(
    'kubernetesApplicationsDatatable',
    r2a(withUIRouter(withCurrentUser(ApplicationsDatatable)), [
      'namespace',
      'namespaces',
      'onNamespaceChange',
      'onRefresh',
      'onRemove',
      'hideStacks',
    ])
  ).name;
